<template>
    <div class="Profile-settings">
        <form class="Profile-settings__block" @submit.prevent="changePassword" ref="form">
            <div class="Profile-settings__title">
                {{$t("profile.settings.title")}}
            </div>
            <div class="Profile-settings__title-desc">
                {{$t("profile.settings.title-desc")}}
            </div>

            <ValidationObserver>
                <ValidationProvider rules="required" v-slot="{ errors }">
                    <div class="form-group">
                        <el-form @submit.prevent="changePassword">
                            <el-form-item
                                :class="{'is-error': errors.length}"
                                :label="$t('profile.settings.old-password')"
                                prop="pass">
                                <el-input
                                    :placeholder="$t('profile.settings.old-password')"
                                    type="password"
                                    show-password
                                    v-model="oldPassword"></el-input>
                                <span class="password-error">{{ errors[0] }}</span>
                            </el-form-item>
                        </el-form>
                    </div>
                </ValidationProvider>
                <ValidationProvider rules="required|confirmed:confirmation" v-slot="{ errors }">
                    <div class="form-group">
                        <el-form>
                            <el-form-item
                                :class="{'is-error': errors.length}"
                                :label="$t('profile.settings.new-password')"
                                prop="pass">
                                <el-input
                                    :placeholder="$t('profile.settings.new-password')"
                                    type="password"
                                    show-password
                                    v-model="newPassword"></el-input>
                                <span class="password-error">{{ errors[0] }}</span>
                            </el-form-item>
                        </el-form>
                    </div>
                </ValidationProvider>

                <ValidationProvider v-slot="{ errors }" rules="required" vid="confirmation">
                    <div class="form-group">
                        <el-form>
                            <el-form-item
                                :class="{'is-error': errors.length}"
                                :label="$t('profile.settings.password-repeat')"
                                prop="pass">
                                <el-input
                                    :placeholder="$t('profile.settings.password-repeat')"
                                    type="password"
                                    show-password
                                    v-model="repeatPassword"></el-input>
                                <span class="password-error">{{ errors[0] }}</span>
                            </el-form-item>
                        </el-form>
                    </div>
                </ValidationProvider>
            </ValidationObserver>

            <div class="Profile-settings__title">
                {{$t("profile.settings.notification-settings")}}
            </div>
            <div class="Profile-settings__title-desc">
                {{$t("profile.settings.notification-access")}}
            </div>

            <div class="Profile-settings__notification">
                <div class="Profile-settings__notification-title">
                    {{$t("profile.settings.sms")}}
                </div>
                <div>
                    <el-switch
                        v-model="sms"
                        active-color="#473F95"
                        inactive-color="#3c3c432e">
                    </el-switch>
                </div>
            </div>
            <div class="Profile-settings__notification">
                <div class="Profile-settings__notification-title">
                    {{$t("profile.settings.account")}}
                </div>
                <div>
                    <el-switch
                        v-model="account"
                        active-color="#473F95"
                        inactive-color="#3c3c432e">
                    </el-switch>
                </div>
            </div>
            <div class="Profile-settings__notification">
                <div class="Profile-settings__notification-title">
                    {{$t("profile.settings.email")}}
                </div>
                <div>
                    <el-switch
                        v-model="email"
                        active-color="#473F95"
                        inactive-color="#3c3c432e">
                    </el-switch>
                </div>
            </div>
            <button class="Profile-settings__save-btn" type="submit">
                {{$t("profile.settings.save-btn")}}
            </button>
        </form>
    </div>
</template>
<script>
import { ValidationObserver, ValidationProvider, extend, useForm } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import Vue from 'vue';

export default {
    beforeCreate() {
        Object.keys(rules).forEach(rule => {
            extend(rule, rules[rule]);
        });
        extend('required', {
            message: 'Это поле является обязательным.'
        });
        extend('confirmed', {
            message: 'Введенные пароли должны совпадать.'
        });
    },
    components: {
        ValidationObserver, 
        ValidationProvider, 
        extend
    },
    data(){
        return {
            oldPassword: '',
            newPassword: '',
            repeatPassword: '',
            sms: false,
            account: true,
            email: true
        }
    },
    methods: {
        changePassword () {
            this.loading = true;

            if (this.newPassword !== this.repeatPassword) {
                Vue.toastr({
                    message: 'Ошибка',
                    description: "Ошибка при обновлении пароля" ,
                    type: 'error'
                });

                return
            }
            this.$http.post(window.API_ROOT + '/api/password/update', {
                oldPassword: this.oldPassword,
                password: this.newPassword
            })
                .then(() => {
                    Vue.toastr({
                        message: 'Успешно!',
                        description: "Пароль успешно обновлен",
                        type: 'success'
                    });
                    this.loading = false
                    this.goToProfile()
                }).catch(() => {
                    Vue.toastr({
                        message: 'Ошибка',
                        description: "Ошибка при обновлении пароля" ,
                        type: 'error'
                    });
                    this.loading = false
                    this.goToProfile()
                });
        },
        goToProfile() {
            if (localStorage.getItem('role_id') === '1') {
                this.$router.push({ name: 'student-profile'})
            } else if (localStorage.getItem('role_id') === '2') {
                this.$router.push({ name: 'teacher-profile'})
            }
        }
    }
}
</script>
<style lang="less" scoped>
.Profile-settings__block {
    background: #FFFFFF;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.04), 0 4px 16px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    padding: 45px 65px;
    max-width: 543px;
    color: #303030;
    font-family: 'Roboto', sans-serif;
    margin: 0 0 32px 0;
}
.Profile-settings__title {
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
    margin-bottom: 5px;
}
.Profile-settings__title-desc {
    color: #A9A9A9;
    font-family: 'Open Sans', 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    max-width: 250px;
    margin-bottom: 30px;
}
.Profile-settings .form-group {
    margin-bottom: 23px;
    position: relative;
}
.Profile-settings .form-group label {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 12px;
}
.Profile-settings .form-group .form-control {
    border: 1px solid #E0E0E0;
    box-sizing: border-box;
    border-radius: 4px;
    box-shadow: none;
    padding: 5px 12px;
}
.Profile-settings .form-group .form-control::placeholder {
    font-size: 13px;
    line-height: 15px;
    font-family: 'Roboto', sans-serif;
    color: #A9A9A9;
}
.Profile-settings__notification {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}
.Profile-settings__notification-title {
    color: #000000;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
}
.Profile-settings__save-btn {
    background: #473F95;
    border-radius: 5px;
    color: #FFFFFF;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    border: none;
    width: 100%;
    padding: 14px;
    margin-top: 10px;
}
/deep/ .el-form-item__label{
    line-height: 20px;
}
.password-error {
    display: block;
    color: #D23168;
    font-size: 11px;
    position: absolute;
    bottom: -30px;
}
@media (max-width: 991px) {
    .Profile_page_content {
       padding: 35px 16px 0 16px;
    }
    .Profile-settings__block {
        padding: 20px;
        margin-bottom: 0;
    }
    .Profile-settings__save-btn {
        margin-top: 20px;
    }
}
</style>